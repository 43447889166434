import React from 'react'
import { graphql } from 'gatsby'

import Layout from  '../components/common/layout'
import Seo from "../components/common/seo"

const CategoryPage = (props) => {
  console.log('pageContext', props.pageContext)
  return (
    <Layout>
      <Seo
        title={props.pageContext.category.page_title || props.pageContext.category.name}
        description={props.pageContext.meta_description}
        />
      <h1>{props.pageContext.category.name}</h1>
      <p>{props.pageContext.category.description}</p>
    </Layout>
  )
}

export default CategoryPage;